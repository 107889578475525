import AuthApi from "../api/AuthApi";
import ConversationAPI from "../api/ConversationApi";
import ConversationRepository from "../repository/ConversationRepository";
import MessageRepository from "../repository/MessageRepository";
import Message from "./Message";

export default class Conversation {
    constructor(object) {
        this.id = object.id;
        this.title = object.title;
        this.date = object.date ?? new Date();
        this.isActive = object.isActive ?? false;
        this.synced = object.synced ?? false;
        this.status = object.status ?? 'draft';
    }

    fromWordpressAPI(jsonArray) {
        jsonArray = jsonArray.map((json) => {
            return {
                title: json.title,
            }
        });
        return Conversation.fromList(jsonArray);
    }

    toWordpressJson() {
        let jsonData = {};
        if(this.synced === true) {
            this.status = 'publish';
        }
        jsonData['title'] = this.title;
        jsonData['status'] = this.status;
        return JSON.stringify(jsonData);
    }

    static clearEmptyConversations() {
        let conversations = ConversationRepository.getConversations();
        conversations = conversations.filter(c => {
            const messages = c.getMessages();
            return messages.length > 0;
        });
        ConversationRepository.saveAll(conversations);
    }

   

    /**
     * 
     * @returns {Conversation}
     */
    static init() {
        //Conversation.clearEmptyConversations();
        let conversation = new Conversation({
            id: ConversationRepository.nextId()
        });
        conversation.setActive();
        return conversation;
    }

    /**
     * 
     * @param {Message} message 
     */
    addMessage(message) {
        message.conversationId = this.id;
        message = MessageRepository.save(message);

        if(AuthApi.isLoggedIn()) {
            if(this.synced === false && this.getMessages().length === 1) {
                this.status = 'publish';
                // on the first message only
                ConversationAPI.post(this).then(conversation => {
                    localStorage.setItem('last-c-id', conversation.id);
                    this.id = conversation.id;
                    this.synced = conversation.synced;
                });
            } else if (this.synced === true && this.getMessages().length === 2) {
                this.status = 'publish';
                // Has been synced already, you need to updated
                ConversationAPI.post(this).then(conversation => {
                    this.title = conversation.title;
                });
            }
        }
        return message;
    }

    getMessages() {
        return this.id === null ? [] : MessageRepository.findByConversationId(this.id);
    }

    /**
     * 
     * @returns {Message|null}
     */
    static getLastMessage(role = null) {
        let conversation = ConversationRepository.getActiveConversation();
        let messages  = conversation.getMessages();

        if(role) {
            messages = messages.filter((message) => message.sender === role);
        }

        let lastElement = messages.length > 0 ? messages[messages.length - 1] : undefined;
        return lastElement;
    }

    setActive() {
        this.isActive = true;
        let conversations = ConversationRepository.getConversations();
        conversations = conversations.map(c => {
            if (this.id === c.id) {
                c.isActive = true;
            } else {
                c.isActive = false;
            }
            return c;
        });
        ConversationRepository.saveAll(conversations);
    }

    /**
    *
    * @param {Array} objectList
    * @returns {Conversation[]}
    */
    static fromList(objectList = []) {
        if (objectList.length === 0)
            return objectList;
        return objectList.map(o => new Conversation(o));
    }
}
import MessageAPI from "../api/MessageApi";
import AppConst from "../AppConst";
import Message from "../model/Message";


export default class MessageRepository {
    static TABLE_NAME = "message";

    static getMessageCount() {
        return this.getMessages().length;
    }

    /**
     * 
     * @returns {number}
     */
    static nextId() {
        let lastId = MessageRepository.lastId();
        lastId++;
        localStorage.setItem('last-m-id', lastId);
        return lastId;
    }

    /**
     * 
     * @returns {number}
     */
    static lastId() {
        let lastId = localStorage.getItem('last-m-id') ?? 0;
        return parseInt(lastId);
    }

    /**
     * 
     * @returns {Message[]}
     */
    static getMessages() {
        const jsonList = JSON.parse(localStorage.getItem(this.TABLE_NAME));
        const messages = Message.fromList(jsonList ?? []);
        return messages;
    }


    /**
     * 
     * @returns {Message[]}
     */
    static findByConversationId(conversationId) {
        let messages = this.getMessages();
        return messages.filter(m => m.conversationId === conversationId);
    }


    static saveAll(messages) {
        localStorage.setItem(this.TABLE_NAME, JSON.stringify(messages));
    }

    /**
     * 
     * @param {Message} message 
     */
    static save(message) {
        let messages = MessageRepository.getMessages();
        if (message.id == null) {
            message.id = MessageRepository.nextId();
            messages.push(message);
        } else {
            messages = messages.map(m => {
                if (m.id === message.id) {
                    return message;
                } else {
                    return m;
                }
            });
          
        }
        localStorage.setItem(this.TABLE_NAME, JSON.stringify(messages));
        MessageAPI.post(message);
        return messages;
    }


}
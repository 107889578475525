import React from "react";
import udmey from "../assets/udmey-icon.png";
import demand from "../assets/demand.png";
import price from "../assets/price.png";
import time from "../assets/time.png";
import arrowRight from "../assets/arrow-right.png";
import Message, { MessageSender } from "../model/Message";
import ConversationRepository from "../repository/ConversationRepository";
import ChatApi from "../api/Api";
import Course from "../model/Course";
import Conversation from "../model/Conversation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faChevronRight, faClock, faCode, faGraduationCap, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import MessageRepository from "../repository/MessageRepository";
import AuthApi from "../api/AuthApi";
import AppHeader from "./AppHeader";
import LoginForm from "./Login";
import WelcomeMessage from "./WelcomeMessage";
import "./chat.css";
import "./chat.new.css";
import "./chat.mobile.css";

const MESSAGE_COUNT = 6;

export default class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isConversationLoaded: false,
            inputValue: '',
            isInputDisabled: false,
            isLoginFormActive: false,
            isLoggedIn: AuthApi.isLoggedIn(),
            conversation: null,
            conversations: ConversationRepository.getConversations(),
        };

        this.promptExamples = [
            {
                "id": 1,
                "text": "Find me top Python Courses",
                "icon": <FontAwesomeIcon color="#FF5733" icon={faCode}></FontAwesomeIcon>
            },
            {
                "id": 2,
                "text": "Make me job ready in Javascript",
                'icon': <FontAwesomeIcon color="#33B5FF" icon={faLaptopCode}></FontAwesomeIcon>
            },
            {
                "id": 3,
                "text": "Give time management courses 1 hr max.",
                'icon': <FontAwesomeIcon color="#FFC300" icon={faClock}></FontAwesomeIcon>
            },
            {
                "id": 4,
                "text": "I want to practice GRE maths",
                'icon': <FontAwesomeIcon color="#28A745" icon={faGraduationCap}></FontAwesomeIcon>
            }
        ];


        this.messagesEndRef = React.createRef();
    }

    componentDidMount() {
        // sync data

        if (this.state.conversation == null) {
            Conversation.init()
            Conversation.clearEmptyConversations();
            const urlParams = new URLSearchParams(window.location.search);
            const conversationId = urlParams.get('id'); // '123'
            if(conversationId !== undefined) {
                let conversationFound = ConversationRepository.getConversation(conversationId);
                if(conversationFound) {
                    this.setConversation(ConversationRepository.getActiveConversation(conversationId));
                } else {
                    this.setConversation(ConversationRepository.getActiveConversation());
                }
            } else {
                this.setConversation(ConversationRepository.getActiveConversation());
            }
           
        }
    }


    // Function to scroll to the bottom
    scrollToBottom = () => {
        setTimeout(() => {
            this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    handleSubmission = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior (page reload)
        const prompt = this.state.inputValue;
        this.triggerSearch(prompt);
    };

    onKeyUp = (e) => {
        if (e.altKey === false && e.keyCode === 13 && e.key === "Enter") {
            this.handleSubmission(e);
        }
    }

    triggerSearch = async (prompt) => {
        if (MessageRepository.getMessageCount() > MESSAGE_COUNT && AuthApi.isTokenExpired()) {
            this.setIsLoginFormActive(true);
        } else {
            let conversation = this.state.conversation;

            this.setInputValue('')
            this.setIsInputDisabled(true);
            const message = new Message({
                content: prompt,
                sender: MessageSender.ME
            });
            conversation.addMessage(message);
            this.setConversation(conversation);
            this.setConversations(ConversationRepository.getConversations());

            let futureMessage = new Message({
                conversationId: conversation.id,
                content: null,
                sender: MessageSender.ASSISTANT
            });

            conversation.addMessage(futureMessage);
            this.setConversation(conversation);
            this.setConversations(ConversationRepository.getConversations());

            const response = await ChatApi.search(prompt, conversation);
            const jsonData = await response.json();

            // futureMessage.conversationId = conversation.id;
            futureMessage.content = jsonData.message;
            futureMessage.see_more = jsonData.see_more;
            futureMessage.keywords = jsonData.keywords;
            futureMessage.courses = Course.fromList(jsonData.results).slice(0, 4);
            conversation.title ??= jsonData.title;

            conversation.addMessage(futureMessage);
            conversation = ConversationRepository.save(conversation);
            this.setConversation(conversation);
            this.setConversations(ConversationRepository.getConversations());

            this.setIsInputDisabled(false);
            this.scrollToBottom();
        }
    }

    onConversationClick = (id) => {
        let conversation = ConversationRepository.getConversation(id);
        if (conversation) {
            conversation.setActive();

            // Assume conversationId is already defined
            const conversationId = conversation.id;
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('id', conversationId);
            window.history.replaceState({}, '', currentUrl.toString());

            this.setConversation(conversation);
            this.setConversations(ConversationRepository.getConversations());
            this.scrollToBottom();
        }
    }

    onCourseClick = (url) => {
        window.open(url, '_blank');
    }

    onCategoryClicked = (e, category) => {
        e.preventDefault();
        this.triggerSearch("I'm looking to learn " + category.name, this.state.conversation.getMessages());
        this.scrollToBottom();
    }

    onNewConversationClicked = () => {
        Conversation.init()
        Conversation.clearEmptyConversations();
        this.setConversation(ConversationRepository.getActiveConversation());
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('id');
        window.history.replaceState({}, '', currentUrl.toString());
    }

    notHappy = () => {
        let lastMessage = Conversation.getLastMessage(MessageSender.ASSISTANT);
        if (lastMessage) {
            let firstKeyword = lastMessage.keywords.length > 0 ? lastMessage.keywords[0] : undefined;

            if (firstKeyword) {
                this.triggerSearch("Show me more courses about " + firstKeyword, this.state.conversation.getMessages());
            }
        }
    }


    deleteConversation = (id) => {
        const conversations = ConversationRepository.deleteConversation(id);
        this.setConversations(conversations);

        const conversation = Conversation.init();
        conversation.setActive();
        this.setConversation(conversation);

        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('id');
        window.history.replaceState({}, '', currentUrl.toString());
    }

    _renderInput = () => {
        return <div className="chat-input-con">
            <form className="input-area" onSubmit={this.handleSubmission}>
                <input
                    type="text"
                    placeholder="What do you want to learn today?"
                    className="chat-input"
                    onKeyUp={this.onKeyUp}
                    disabled={this.state.isInputDisabled}
                    value={this.state.inputValue}  // Bind input value to state
                    onChange={(e) => this.setInputValue(e.target.value)}
                />

                <button className="submit-input">
                    <FontAwesomeIcon color="#000000" icon={faChevronRight}></FontAwesomeIcon>
                </button>
            </form>
        </div>;
    }

    render() {
        return <>

            <div className="chat-app">
                <AppHeader isLoggedIn={this.state.isLoggedIn} setIsLoggedIn={this.setIsLoggedIn} setIsLoginFormActive={this.setIsLoginFormActive} deleteConversation={this.deleteConversation} onConversationClick={this.onConversationClick} conversations={this.state.conversations} onNewConversationClicked={() => this.onNewConversationClicked()} ></AppHeader>
                <div className="chat-main">
                    <div className="chat-con">

                        <div className="chat-listing">
                            {this.state.conversation && this.state.conversation.getMessages().length > 0 ? this.state.conversation.getMessages().map(m => m.sender === MessageSender.ME ?
                                <div key={m.id} className="your-prompt">
                                    <div>
                                        <p>{m.content}</p>
                                    </div>
                                </div> :
                                <div key={m.id} className="search-prompt" data-gradient={m && m.courses.length === 0}>
                                    <p className="search-prompt-heading">
                                        {m.content ?? "I'm working on your request. Please be with me..."}
                                    </p>

                                    <div className="course-listing">
                                        {m && m.courses ? m.courses
                                            .map((c) =>
                                                <div key={c.id} onClick={() => this.onCourseClick(c.url)} className="card">
                                                    <img
                                                        src={c.featured_image}
                                                        alt=""
                                                        className="card-image"
                                                    />
                                                    <span className="card-category">{c.getCategoryNames()}</span>
                                                    <p className="card-title" dangerouslySetInnerHTML={{ __html: c.title }} />

                                                    <ul className="card-ul">
                                                        <li className="card-li">
                                                            {" "}
                                                            <img src={udmey} alt="" className="card-li-img" />
                                                            <p className="card-li-text">{c.provider}</p>
                                                        </li>
                                                        {c.course_session.trim().length > 0 ? <li className="card-li">
                                                            <img src={demand} alt="" className="card-li-img" />
                                                            <p className="card-li-text">{c.course_session}</p>
                                                        </li> : <></>}

                                                        <li className="card-li">
                                                            <img src={price} alt="" className="card-li-img" />
                                                            <p className="card-li-text">{c.course_type}</p>
                                                        </li>
                                                        {c.duration.trim().length > 0 ? <li className="card-li">
                                                            {" "}
                                                            <img src={time} alt="" className="card-li-img" />
                                                            <p className="card-li-text">{c.duration}</p>
                                                        </li> : <></>}

                                                    </ul>
                                                    <a href={c.url}>
                                                        <div className="card-button-div">
                                                            <p className="card-button-text">Read More</p>
                                                            <div className="card-button-img-div">
                                                                <img
                                                                    src={arrowRight}
                                                                    alt=""
                                                                    className="card-button-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ) : <></>}
                                    </div>

                                    {m.getCategories().length > 0 ? <>
                                        <div className="course-categories">
                                            <h4>Related categories</h4>
                                            <div className="list">
                                                {m.getCategories().length > 0 ? m.getCategories().map((course, index) => (
                                                    <a key={index} rel="norefferer" onClick={(e) => this.onCategoryClicked(e, course)} className="category" href={course.url} dangerouslySetInnerHTML={{ __html: course.name }}></a>
                                                )) : <></>}
                                            </div>
                                        </div>
                                        <div className="quick-actions">
                                            {m.courses.length > 0 ? <button className="notHappy" onClick={this.notHappy}>Suggest me more courses</button> : <></>}
                                            {m.courses.length > 0 ? <a className="seeMore" target="_blank" rel="noreferrer" href={m.see_more}>
                                                <span>See more</span>
                                                <FontAwesomeIcon color="#ffffff" icon={faArrowUpRightFromSquare} />
                                            </a> : <></>}
                                        </div>
                                    </> : <></>}



                                </div>


                            ) : <WelcomeMessage promptExamples={this.promptExamples} triggerSearch={this.triggerSearch} ></WelcomeMessage>}
                        </div>

                        <div ref={this.messagesEndRef} />
                    </div>
                    {this._renderInput()}

                </div>

            </div>
            <LoginForm isLoggedIn={this.state.isLoggedIn} setIsLoggedIn={this.setIsLoggedIn} setIsLoginFormActive={this.setIsLoginFormActive} isLoginFormActive={this.state.isLoginFormActive}></LoginForm>
        </>

    }


    setIsConversationLoaded = (value) => {
        this.setState({ isConversationLoaded: value });
    };

    setInputValue = (value) => {
        this.setState({ inputValue: value });
    };

    setIsInputDisabled = (value) => {
        this.setState({ isInputDisabled: value });
    };

    setIsLoginFormActive = (value) => {
        this.setState({ isLoginFormActive: value });
    };

    setIsLoggedIn = (value) => {
        this.setState({ isLoggedIn: value });
    };

    setConversation = (value) => {
        this.setState({ conversation: value });
    };

    setConversations = (value) => {
        this.setState({ conversations: value });
    };
} 
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ConversationRepository from "../repository/ConversationRepository";
import './ConversationWidget.css';
import './ConversationWidget.mobile.css';

export default class ConversationWidget extends React.Component {

    render() {
        return <>
            {/* Only show the overlay if the sidebar is visible */}
            {this.props.isVisible && <div className="overlay transparent" onClick={this.props.toggleConversations}></div>}
            
            <div className={`conversation-widget ${this.props.isVisible ? 'visible' : ''}`}>

                {this.props.conversations ? Object.entries(ConversationRepository.categorizeConversations(this.props.conversations))
                    .map(([key, value], index) => {
                        return {
                            index: index,
                            category: key,
                            list: value
                        };
                    })
                    .filter((c) => c.list.length > 0)
                    .map(c => <>
                        <span key={c.index} className="chat-sidebar-1-span">{c.category}</span>
                        {c.list.map(l => <div className="chat-sidebar-field">
                            <button data-active={l.isActive === true} onClick={() => {
                                this.props.onConversationClick(l.id);
                                this.props.toggleConversations();
                            }}>{l.title ?? "Current conversation"}</button>
                            <button onClick={() => {
                                this.props.deleteConversation(l.id);
                                const conversations = ConversationRepository.getConversations();
                                if(conversations.length === 0) {
                                    this.props.toggleConversations();
                                }
                            }} className="delete-conversation">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>)}
                    </>
                    ) : <></>}


            </div>
        </>
    }
}